<template>
  <div id="extracts">
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <h1>Extracts</h1>
          <h4>{{ name }}</h4>
        </b-col>
      </b-row>
      <div v-if="busy" class="text-center"><Busy primary text="Loading..." /></div>
      <b-row v-else>
        <b-table responsive selectable :items="extracts" :fields="fields">
          <template v-slot:cell(showFilteredAccounts)="row">
            <b-button
              v-if="row.item.filteredAccountCodes"
              size="sm"
              @click="row.toggleDetails"
              class="mr-2"
              >{{ row.detailsShowing ? "Hide" : "Show" }}</b-button
            >
          </template>

          <template v-slot:row-details="row">
            <b-card>
              <h5>Filtered accounts</h5>
              <ul>
                <li v-for="(value, index) in row.item.filteredAccountCodes" v-bind:key="index">
                  {{ value }}
                </li>
              </ul>
              <b-button size="sm" @click="row.toggleDetails">Hide</b-button>
            </b-card>
          </template>
          <template v-slot:cell(userEmail)="row">
            {{ row.item.userEmail }}
            <small
              v-if="
                row.item.startedByUserEmail && row.item.startedByUserEmail != row.item.userEmail
              "
              >Run by: {{ row.item.startedByUserEmail }}</small
            >
          </template>
          <template v-slot:cell(state)="row">
            {{ row.value }}
            <div v-if="row.item.limited" class="reducer-badge">
              <b-badge variant="warning">
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="mr-1" />Limited
                data
              </b-badge>
            </div>
          </template>
          <template v-slot:cell(startTime)="row">{{
            formatDateTimeToMin(row.item.startTime)
          }}</template>
          <template v-slot:cell(endTime)="row">{{
            formatDateTimeToMin(row.item.endTime)
          }}</template>
        </b-table>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";

export default {
  name: "ExtractJobs",
  components: { Busy },
  props: {
    extracts: Array,
    name: String,
    busy: Boolean
  },
  data() {
    return {
      fields: [
        { key: "showFilteredAccounts", label: "Filtered Accounts" },
        { key: "orgName" },
        { key: "userEmail" },
        { key: "state" },
        {
          key: "startTime",
          label: "Start"
        },
        {
          key: "endTime",
          label: "End"
        },
        { key: "runningMins", label: "Run Mins" },
        { key: "totalNumberOfInvoices", label: "Total Invoices" },
        {
          key: "totalNumberOfInvoicesWithAttachments",
          label: "Total Invoices with attachments"
        },
        { key: "totalNumberOfBankTransactions", label: "Total BTs" },
        {
          key: "totalNumberOfBankTransactionsWithAttachments",
          label: "Total BTs with attachments"
        },
        {
          key: "numberOfPurchasesProcessed",
          label: "Total Purchases"
        },
        {
          key: "numberOfPurchasesWithAttachmentsProcessed",
          label: "Total Purchases with attachments"
        },
        {
          key: "numberOfInvoicesFilteredOutAsWages",
          label: "Items/Invoices filtered out as wages"
        },
        {
          key: "numberOfBankTransactionsFilteredOutAsWages",
          label: "BTs filtered out as wages"
        },
        {
          key: "numberOfFailedAttachmentDownloads",
          label: "Attachments failed to download"
        },
        { key: "numberOfAttachmentsProcessed", label: "Attachments processed" },
        {
          key: "numberOfAttachmentsAlreadyInDatabase",
          label: "Attachments already in DB"
        },
        {
          key: "numberOfBankStatementsDownloaded",
          label: "Reports downloaded"
        },
        { key: "numberOfInvoiceDuplicates", label: "Invoice duplicates" },
        { key: "numberOfBankTransactionDuplicates", label: "BT duplicates" },
        { key: "numberOfPurchaseDuplicates", label: "Purchase duplicates" },
        { key: "numberOfInvoicesProcessed", label: "Invoices processed" },
        {
          key: "numberOfInvoicesWithAttachmentsProcessed",
          label: "Invoices with attachments processed"
        },
        { key: "numberOfBankTransactionsProcessed", label: "BTs processed" },
        {
          key: "numberOfBankTransactionsWithAttachmentsProcessed",
          label: "BTs with attachments processed"
        }
      ]
    };
  },
  methods: {
    formatDateTimeToMin: FormatHelper.formatDateTimeToMin
  }
};
</script>