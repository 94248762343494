<template>
  <div id="admin-org-extracts">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <ExtractJobs :extracts="extracts" :name="name" :busy="busy" />
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import ExtractJobs from "@/components/ExtractJobs";
import Console from "@/console";

export default {
  name: "admin-org-extracts",
  components: { ExtractJobs },
  created() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData"
  },
  data() {
    return {
      title: "Organisation Extracts",
      extracts: [],
      name: null,
      busy: true
    };
  },
  methods: {
    async fetchData() {
      try {
        var organisationId = this.$route.params.organisationId;
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}extracts?organisationId=${organisationId}&itemsPerPage=999`
        );

        if (response.data.status === "success") {
          Console.log(response.data.extracts);
          this.extracts = response.data.extracts;
          this.name = this.extracts[0].orgName;
        } else {
          this.showWarning("There was a problem getting extracts.");
        }
      } catch {
        this.showWarning("There was a problem getting extracts.");
      } finally {
        this.busy = false;
      }
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    }
  }
};
</script>